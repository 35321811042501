    <template>
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">

          <div class="max-w-sm md:max-w-2xl xl:max-w-none mx-auto grid gap-8 md:grid-cols-1 xl:grid-cols-3 xl:gap-6 items-start">

            <!-- Kisvalit -->
            <div class="relative flex flex-col h-full py-5 px-6 rounded bg-white shadow-xl">
              <div class="mb-4">
                <div class="text-lg font-bold mb-1">Kisvállalati csomag</div>
                <div class="mb-2">
                  <span class="text-2xl font-bold">
                    <b class="text-xl">€</b>1095
                  </span><br>
                  <span class="text-gray-600">/ év – bevezető ár 2022.12.31-ig</span><br><br>
                </div>
                <div class="text-lg text-gray-800">Tartsa az irányítást saját kézben!</div>
              </div>
              <ul class="text-gray-600 -mb-2 flex-grow">
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>5 felhasználó BUSINESS licenc</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>1 felhasználó BUSINESS PRÉMIUM licenc</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Fél napos oktatás</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Ügyfél támogatás</span>
                </li>
              </ul>
              <div class="border-t border-gray-200 pt-5 mt-6">
                <router-link class="btn-sm text-white bg-blue-800 hover:bg-blue-700 w-full" to="/kapcsolat">Ajánlatkérés / Megrendelés</router-link>
              </div>
            </div>

            <!-- Business -->
            <div class="relative flex flex-col h-full py-5 px-6 rounded bg-blue-100 shadow-xl border-2 border-blue-800" data-aos="zoom-y-out" data-aos-delay="150">
              <div class="absolute top-0 right-0 mr-5 p-3 -mt-5 bg-yellow-300 rounded-full">
                <svg class="w-4 h-4 fill-current text-white" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.145 5.05l-4.316-.627L8.898.513c-.338-.684-1.456-.684-1.794 0l-1.93 3.91-4.317.627a1.002 1.002 0 00-.554 1.707l3.124 3.044-.737 4.3a1 1 0 001.45 1.053L8 13.125l3.862 2.03c.728.381 1.59-.234 1.45-1.054l-.736-4.299L15.7 6.758a1.003 1.003 0 00-.555-1.708z" />
                </svg>
              </div>
              <div class="mb-4">
                <div class="text-lg font-bold mb-1">BUSINESS</div>
                <div class="inline-flex items-center mb-2">
                  <div>
                    <span class="text-3xl font-bold">{{priceOutput.plan2[value][0]}}</span>
                    <span class="text-4xl font-bold">{{priceOutput.plan2[value][1]}}</span>
                  </div>
                  <div class="ml-4">
                    <span class="text-gray-600" v-html="priceOutput.plan2[value][2]"></span>
                  </div>
                </div>
                <div class="text-lg text-gray-800">Teljes csapat együttműködés!</div>
              </div>
              <ul class="text-gray-600 -mb-2 flex-grow">
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>PERSONAL +</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Korlátlan számú projekt létrehozása</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Korlátlan számú projekttag</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Korlátlan számú adat feltöltése és megosztása</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Korlátlan számú projekt megosztása és hozzá csatlakozás</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Projektek áttekintése és kezelése</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Felhasználók áttekintése és kezelése</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Fájlok metaadatainak meghatározása</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>További bővítések a Tekla ügyfelei számára</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Adatok összekapcsolása az egyes fázisokon keresztül a projekt ütemtervének és költségvetésének betartása érdekében</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Az adatok feltöltése, megosztása és integrálása több tucat iparági eszközzel, pl. mobil (iOS, Android), Augmented Reality (AR) és Mixed Reality (MR) eszközökkel</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Ügyfél támogatás – meghatározott licencekhez</span>
                </li>
              </ul>
              <div class="border-t border-gray-200 pt-5 mt-6">
                <router-link class="btn-sm text-white bg-blue-800 hover:bg-blue-700 w-full" to="/kapcsolat">Ajánlatkérés / Megrendelés</router-link>
              </div>
            </div>

            <!-- Business Premium -->
            <div class="relative flex flex-col h-full py-5 px-6 rounded bg-white shadow-xl" data-aos="zoom-y-out" data-aos-delay="300">
              <div class="mb-4">
                <div class="text-lg font-bold mb-1">BUSINESS PRÉMIUM</div>
                <div class="inline-flex items-center mb-2">
                  <div>
                    <span class="text-3xl font-bold">{{priceOutput.plan3[value][0]}}</span>
                    <span class="text-4xl font-bold">{{priceOutput.plan3[value][1]}}</span>
                  </div>
                  <div class="ml-4">
                    <span class="text-gray-600" v-html="priceOutput.plan3[value][2]"></span>
                  </div>
                </div>
                <div class="text-lg text-gray-800">Kibővített alkalmazások és prémium BIM-funkciók!</div>
              </div>
              <ul class="text-gray-600 -mb-2 flex-grow">
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>BUSINESS +</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Továbbfejlesztett, BIM eszközök</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Kiegészített 3D munkafolyamatok</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Hozzáférés egyedi alkalmazásokhoz és bővítményekhez</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Teljes hozzáférés a Trimble Connect munkafolyamat-bővítményeken keresztül létrehozott és megosztott adatokhoz</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Állapotmegosztás</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Szervező alkalmazás</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Egyedi tulajdonságkészletek</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Tartalomböngésző</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Ügyfél támogatás – meghatározott licencekhez</span>
                </li>
              </ul>
              <div class="border-t border-gray-200 pt-5 mt-6">
                <router-link class="btn-sm text-white bg-blue-800 hover:bg-blue-700 w-full" to="/kapcsolat">Ajánlatkérés / Megrendelés</router-link>
              </div>
            </div>

          </div>


          <div class="max-w-sm mt-4 mb-12 md:max-w-2xl xl:max-w-none mx-auto grid gap-8 md:grid-cols-1 xl:grid-cols-3 xl:gap-6 items-start">
            <!-- Trimble XR10 -->
            <div class="relative flex flex-col h-full py-5 px-6 rounded bg-white shadow-xl">
              <div class="mb-4">
                <div class="text-lg font-bold mb-1">Trimble XR10</div>
                <div class="mb-2 flex flex-col">
                  <span class="text-2xl font-bold">
                    <b class="text-xl">€</b>4.900 – <b class="text-2xl">€</b>6.900
                  </span>
                  <span class="text-gray-600">
                    felszereltség és szoftver függvényében – kérje egyedi ajánlatunkat
                  </span>
                </div>
                <div class="text-lg text-gray-800">Augmented + Mixed reality!</div>
              </div>
              <ul class="text-gray-600 -mb-2 flex-grow">
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>BUSINESS PRÉMIUM +</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Trimble Connect MR (vegyes valóság vizualizáció HoloLenshez)</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Trimble Connect AR (Augmented Reality vizualizáció)</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Trimble Connect Business PREMIUM (együttműködés, BIM eszközök)</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Email címmel azonosított Trimble Identity fiókhoz hozzárendelhető licenc</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Ügyfél támogatás – meghatározot licencekhez</span>
                </li>
              </ul>
              <div class="border-t border-gray-200 pt-5 mt-6">
                <router-link class="btn-sm text-white bg-blue-800 hover:bg-blue-700 w-full" to="/kapcsolat">Ajánlatkérés / Megrendelés</router-link>
              </div>
            </div>


            <!-- Szemelyt -->
            <div class="relative flex flex-col h-full py-5 px-6 rounded bg-white shadow-xl">
              <div class="mb-4">
                <div class="text-lg font-bold mb-1">Személyre szabott megoldások</div>
                <div class="flex flex-col mb-2">
                  <span class="text-2xl" style="font-weight: 700; letter-spacing: -.16px;">Egyéni ajánlat alapján</span>
                  <span class="text-gray-600 opacity-0">felszereltség és szoftver függvényében – kérje egyedi ajánlatunkat</span>
                </div>
                <div class="text-lg text-gray-800">Projektekhez vagy vállalatokhoz igazítva!</div>
              </div>
              <ul class="text-gray-600 -mb-2 flex-grow">
                <li class="flex items-top mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mt-2 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>
                    <b>PROJECT Packages:</b> Személyre szabott megoldás nagy projektekhez<br>
                    <span class="text-gray-400">
                      Tartalmazza egy projekthez a BUSINESS PREMIUM hozzáférést minden résztvevő számára, a szervezeten kívüli szereplők részére is
                    </span>
                  </span>
                </li>
                <li class="flex items-top mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mt-2 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>
                    <b>ENTERPRISE Packages:</b> Személyre szabott megoldás nagylétszámú csapatokhoz<br>
                    <span class="text-gray-400">
                      Tartalmazza tetszőleges projekthez a BUSINESS PREMIUM hozzáférést az Ön vállalatának minden tagja számára, valamint az Ön fiókja alatt létrehozott projektek külső felhasználói számára
                    </span>
                  </span>
                </li>
              </ul>
              <div class="border-t border-gray-200 pt-5 mt-6">
                <router-link class="btn-sm text-white bg-blue-800 hover:bg-blue-700 w-full" to="/kapcsolat">Ajánlatkérés / Megrendelés</router-link>
              </div>
            </div>


            <!-- Personal -->
            <div class="relative flex flex-col h-full py-5 px-6 rounded bg-white shadow-xl" data-aos="zoom-y-out">
              <div class="mb-4">
                <div class="text-lg font-bold mb-1">PERSONAL</div>
                <div class="flex items-baseline mb-2">
                  <div>
                    <span class="text-3xl font-bold">{{priceOutput.plan1[value][0]}}</span>
                  </div>
                  <div>
                    <span class="text-4xl font-bold">{{priceOutput.plan1[value][1]}}</span>
                    <span class="text-gray-600 pl-2">{{priceOutput.plan1[value][2]}}</span>
                  </div>
                </div>
                <div class="text-lg text-gray-800">Hozza létre első projektjét!</div>
              </div>
              <ul class="text-gray-600 -mb-2 flex-grow">
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>1 projekt létrehozása</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>A projektre maximum 5 tag meghívása</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Akár 10 GB tárhely</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Csatlakozási lehetőség maximum 5, mások által létrehozott projekthez</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Maximum 5 felhasználó maximum 5 projekttagot hívhat meg</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>BIM koordináció</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Ütközés vizsgálat</span>
                </li>
                <li class="flex items-center mb-2">
                  <svg class="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" /></svg>
                  <span>Feladatkezelési eszközök</span>
                </li>
              </ul>
              <div class="border-t border-gray-200 pt-5 mt-6">
                <a class="btn-sm text-white bg-blue-800 hover:bg-blue-700 w-full" href="https://web.connect.trimble.com/">Próbálja ki ingyen</a>
              </div>
            </div>
          </div>

    </div>
  </section>
</template>

<script>
export default {
  name: 'CenikInfo',
  data: function () {
    return {
      value: true,
      priceOutput: {
        plan1: {
            false: ['€', '0', ''],
            true: ['€', '0', '']
        },
        plan2: {
            false: ['€', '550', '/5 felhasználós csomag<br>/év'],
            true: ['€', '550', '/5 felhasználós csomag<br>/év']
        },
        plan3: {
            false: ['€', '263', '/ 1 felhasználó<br>/ év'],
            true: ['€', '263', '/ 1 felhasználó<br>/ év']
        }
      },
    }
  },
}
</script>

<style>
.fa-info-circle:hover{
  color: #009AD9!important;
  cursor: help;
}
.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: #005F9E;
  color: white;
  border-radius: 1rem;
  padding: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: #005F9E;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(#005F9E, .1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}
</style>
